import React, { Component } from 'react'

class Privacy extends Component {

  constructor(props) {
    super(props)

    this.state = {
      //
    }
  }

  render() {

    const mainTitleStyle = {
      fontSize: '32px',
      fontWeight: '700'
    }
    const mainTitleContextStyle = {
      fontSize: '14px'
    }
    const listContextStyle = {
      fontSize: '13px'
    }
    const titleStyle = {
      fontSize: '18px',
      fontWeight: '600'
    }
    const titleContextStyle = {
      fontSize: '14px'
    }

    return (
      <React.Fragment>
        <div className="policy-wrap">
          <div style={mainTitleStyle}>
            개인정보처리방침
          </div>
          <div style={mainTitleContextStyle}>
            직관적합리주의 주식회사(이하 "회사")의 주거통합플랫폼 서비스인 너나방은 「개인정보 보호법」 제30조에 따라 
            정보주체의 개인정보를 보호하고 이와 관련한 고충을 신속하고 원활하게 처리할 수 있도록 하기 위하여 다음과 같이 개인정보 처리방침을 수립·공개하며 이 개인정보처리방침은 2022년 1월 26부터 적용됩니다.
            </div>
            <br></br>
            <div style={listContextStyle}>
              1. 개인정보의 수집에 대한 동의<br></br>
              2. 개인정보의 수집 항목 및 목적<br></br>
              3. 개인정보의 수집 방법<br></br>
              4. 개인정보의 보유 및 이용기간<br></br>
              5. 수집한 개인정보의 이용 및 제3자 제공<br></br>
              6. 개인정보처리의 위탁<br></br>
              7. 개인정보의 열람 및 정정<br></br>
              8. 개인정보의 수집,이용,제공에 대한 동의철회<br></br>
              9. 개인정보 파기절차 및 방법<br></br>
              10. 개인정보처리방침의 고지 또는 통지방법<br></br>
              11. 개인정보보호 책임자 및 담당자<br></br>
              12. 광고성 정보 전송<br></br>
              13. 개정 전 고지의무<br></br>
              <br></br>
         
          </div>

          <div style={titleStyle}>
            1. 개인정보 수집에 대한 동의
          </div>
          <div style={titleContextStyle}>
            회사는 최초 로그인 시에 개인정보처리방침과 이용약관의 내용에 "동의" 버튼을 클릭할 수 있는 절차를 마련하고 있으며 "동의"버튼을 클릭하면, 개인정보 수집에 동의한 것으로 간주합니다.
            <br></br>
            <br></br>
          </div>

          <div style={titleStyle}>
            2. 개인정보의 수집 항목 및 목적
          </div>
          <div style={titleContextStyle}>
            회사는 기본적인 서비스를 제공하기 위한 다음의 목적을 위하여 개인정보를 처리합니다. 
            처리하고 있는 개인정보는 다음의 목적 이외의 용도로는 이용되지 않으며 
            고객의 기호와 필요에 맞는 서비스를 제공하고자 할 때에는 「개인정보 보호법」 제18조에 따라 별도의 동의를 받는 등 필요한 조치를 이행할 예정입니다.
            <br></br>
            {/* -필수 수집 항목(공통) &gt;&gt; 이메일, 연락처, 서비스이용기록, 접속로그, 아이피주소, 카드 사용 내역 */}
            -필수 수집 항목(공통) &gt;&gt; 이메일, 연락처, 서비스이용기록, 접속로그, 카드 사용 내역
            <br></br>
            -필수 수집 항목 목적 &gt;&gt; 이용자 식별, 서비스 이용에 대한 정보 제공, 서비스 이용 및 결제 관련, 문의사항 처리
            <br></br>
            {/* -필수 수집 항목(중개사 이용자) &gt;&gt; 사무소 이름, 사무소 주소, 연락처 */}
            {/* <br></br> */}
            -필수 수집 항목 목적 &gt;&gt; 이용자 유형 식별, 서비스 이용에 대한 정보 제공
            <br></br>
            <br></br>
          </div>

          <div style={titleStyle}>
            3. 개인정보의 수집 방법
          </div>
          <div style={titleContextStyle}>
            회사는 회원가입 절차, 서비스 접속 시, 상담절차 또는 이벤트 등의 방법으로 개인정보를 수집합니다.
            <br></br>
            <br></br>
          </div>

          <div style={titleStyle}>
            4. 개인정보의 보유 및 이용기간
          </div>
          <div style={titleContextStyle}>
            가. 회사는 고객의 개인정보를 고지 및 동의받은 기간 동안 보유 및 이용하며,
            개인정보의 수집 및 이용목적 달성, 보유기간 만료, 회원의 수집 및 이용 동의 철회시 수집된 개인정보는 이용할 수 없도록 파기 처리합니다.
            <br></br>
            -계약 또는 청약철회 등에 관한 기록 : 5년
            <br></br>
            -대금결제 및 재화 등의 공급에 관한 기록 : 5년
            <br></br>
            -소비자의 불만 또는 분쟁처리에 관한 기록 : 3년
            <br></br>
            회원 정보, 게시글, 부동산 정보 등록 및 부동산 정보글에 대해서는 이용계약이 존속중인 회원(탈퇴하지 아니한 회원)의 경우 보관기간은 보존의무기간 이상 보관할 수 있으며,
            해당 기간이 경과된 기록에 대해서는 해당 정보 작성 고객의 정보 파기 요청이 있는 경우 파기합니다.
            <br></br>
            <br></br>
          </div>

          <div style={titleStyle}>
            5. 수집한 개인정보의 이용 및 제3자 제공
          </div>
          <div style={titleContextStyle}>
          회사는 이용자의 개인정보를 「개인정보의 수집 및 이용목적」에서 알린 범위 내에서 사용합니다. 다만, 고객의 동의가 있는 경우에는 예외로 적용됩니다.
            <br></br>
            <br></br>
          </div>

          <div style={titleStyle}>
            6. 개인정보처리의 위탁
          </div>
          <div style={titleContextStyle}>
          회사는 원활한 서비스 운영을 위하여 이용자의 개인정보 처리를 외부전문기관에 위탁할 수 있습니다.
            <br></br>
            -Amazon Web Service Inc. &gt;&gt; 데이터 처리 및 보관
            <br></br>
            <br></br>
          </div>

          <div style={titleStyle}>
            7. 개인정보의 열람 및 정정
          </div>
          <div style={titleContextStyle}>
            이용자의 개인정보는 상담문의 또는 개인정보보호책임자에게 메시지나 이메일로 열람이나 정정을 요청할 수 있습니다.
            <br></br>
            <br></br>
          </div>

          <div style={titleStyle}>
            8. 개인정보의 수집,이용,제공에 대한 동의철회
          </div>
          <div style={titleContextStyle}>
            회원가입 등을 통한 개인정보의 수집, 이용, 제공과 관련해 귀하는 동의하신 내용을 언제든지 철회 하실 수 있습니다.
            동의철회는 앱의 "내 정보" 페이지에서 "회원탈퇴"를 클릭하면 개인정보의 수집ㆍ이용ㆍ제공에 대한 동의를 철회할 수 있으며,
            해당 방법으로 철회가 안 되는 경우, 상담문의나 개인정보보호책임자에게  메시지나 이메일로 연락하시면 조치를 취할 것입니다.
            <br></br>
            <br></br>
          </div>

          <div style={titleStyle}>
            9. 개인정보 파기절차 및 방법
          </div>
          <div style={titleContextStyle}>
          회사는 수집한 개인정보의 이용목적이 달성된 후에는 보관기간과 이용기간에 따라 해당 정보를 바로 파기합니다. 파기절차, 방법, 시점은 다음과 같습니다.
            <br></br>
            -파기절차 및 시점
            <br></br>
            &gt;&gt; 고객이 서비스 가입 등을 위해 기재한 개인정보는 서비스 해지 등 이용목적이 달성되고 나면 「개인정보의 보유 및 이용기간」에 따른 보유 기간이 지난 후에 삭제되거나 파기합니다.
            <br></br>
            -파기 방법
            <br></br>
            &gt;&gt; 종이에 출력된 개인정보는 분쇄기로 분쇄 또는 소각하거나 화학적 처리를 통하여 파기하고, 전자적 파일형태로 저장된 개인정보는 기록한 이용자를 구별할 수 없게 하는 기술적 방법을 사용하여 삭제 합니다.
            <br></br>
            <br></br>
          </div>

          <div style={titleStyle}>
            10. 개인정보처리방침의 고지 또는 통지방법
          </div>
          <div style={titleContextStyle}>
            -회사는 고객이 동의한 범위를 넘어 고객의 개인정보를 이용하거나 제3자에게 제공하기 위해 고객에게서 추가적인 동의를 얻고자 할 때에는 미리 고객에게 개별적으로 이메일 등으로 해당사항을 알립니다.
            <br></br>
            -회사가 영업의 전부 또는 일부를 양도하거나 합병·상속 등으로 그 권리와 의무를 이전할 때에는 이메일 등을 통하여 고객에게 개별적으로 알리는 동시에 해당 사실을 홈페이지 첫 화면에서 식별할 수 있도록 표기하고 30일 이상 그 사실을 공지합니다.
            다만, 고객의 연락처를 알지 못하거나 천재지변 등 통지할 수 없는 정당한 이유가 있으면 2곳 이상의 중앙일간지(고객의 대부분이 특정 지역에 거주할 때에는 그 지역을 보급구역으로 하는 일간지로 할 수 있습니다)에 1 회 이상 공고하는 것으로 대신합니다.
            <br></br>
            <br></br>
          </div>

          <div style={titleStyle}>
            11. 개인정보보호 책임자 및 담당자
          </div>
          <div style={titleContextStyle}>
            개인정보에 대해서 문의가 생기는 경우, 개인정보처리담당자에게 문의를 할 수 있으며, 담당자의 이메일은 neonabang@neonabang.com 입니다.
            <br></br>
            <br></br>
          </div>

          <div style={titleStyle}>
            12. 광고성 정보 전송
          </div>
          <div style={titleContextStyle}>
            고객의 앱에 알림기능이 켜져있을 경우, 광고성 정보가 전송될 수 있습니다.
            <br></br>
            <br></br>
          </div>

          <div style={titleStyle}>
            13. 개정 전 고지의무
          </div>
          <div style={titleContextStyle}>
          회사는 정부의 정책 또는 보안기술의 변경에 따라 내용의 추가,삭제 및 수정이 필요하면 
          일반적 내용은 개정 최소 7일 전부터, 중요한 내용은 개정 최소 30일 전부터 홈페이지를 통해 알릴 예정입니다.
            <br></br>
            <br></br>
          </div>

          <div style={titleStyle}>
        부칙
        </div>
        <div style={titleContextStyle}>
        이 약관은 2022년 1월 26일부터 시행한다.
        <br></br>
        <br></br>
        </div>
        </div>
      </React.Fragment>
    )
  }
}

export default Privacy
