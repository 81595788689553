import { authConstants } from '../../constants'
import TestData from '../../../data/userInfo.json'

const initialState = {
  params: '',
  user: ''
}

export default function auth(state = initialState, action) {
  switch (action.type) {
    case authConstants.USER_INFO_REQUEST:
      return {
        ...state,
        params: action.params,
        user: TestData.payload
      }

    default:
      return state
  }
}
