import React, { Component } from 'react'

class ItemEstate extends Component {

  constructor(props) {
    super(props)

    this.state = {
      //
    }
  }

  render() {

    const mainTitleStyle = {
      fontSize: '32px',
      fontWeight: '700'
    }
    const mainSubTitleStyle = {
      fontSize: '24px',
      fontWeight: '600'
    }
    const titleStyle = {
      fontSize: '18px',
      fontWeight: '600'
    }
    const titleContextStyle = {
      fontSize: '14px'
    }

    return (
      <React.Fragment>
        <div className="policy-wrap">
        <div style={mainTitleStyle}>
        부동산관리규정
        </div>

        <div style={titleStyle}>
        제 1조 (규정의 목적)
        </div>
        <div style={titleContextStyle}>
        이 정책은 회원들 간의 신뢰를 높이고 직관적합리주의 주식회사(이하 "회사")가 운영하는 너나방의 인터넷 사이트 또는 모바일 어플리케이션 서비스(이하 "서비스")의 질을 높임으로써
        동산 정보 교환 서비스 활성화를 목적으로 한다.
        <br></br>
        <br></br>
        </div>

        <div style={titleStyle}>
        제 2조 (정책 적용 대상)
        </div>
        <div style={titleContextStyle}>
        이 정책은 회사가 제공하는 서비스의 전 회원에 적용됩니다.
        <br></br>
        <br></br>
        </div>

        <div style={titleStyle}>
        제 3조 (부동산 정보 작성 규정)
        </div>
        <div style={titleContextStyle}>
        ①등록 가능한 부동산
        <br></br>
        &nbsp;&nbsp; 1. 회원은 단독주택, 공동주택, 오피스텔, 아파트에 해당하는 부동산의 정보를 등록할 수 있습니다.단, 불법 개조·증축된 부동산이라는 사실이 확인된 경우에는 해당 정보를 제공자에게 통보 없이
        즉시 삭제처리됩니다.<br></br>
        &nbsp;&nbsp; 2. 회원은 부동산의 매매에 관한 정보는 등록할 수 없습니다.<br></br>
        ②위치 입력
        <br></br>
        부동산 정보 등록 시 도로명주소를 포함하여 지번과 동, 층, 호수 등의 정확한 주소를 입력해야 합니다.<br></br>
{/*
        ③가격 정보 입력
        <br></br>
        &nbsp;&nbsp; 1. 전세, 매매 매물 등록 시에 매물 가격은 단일 기본 가격으로 등록해야 합니다.
        <br></br>
        &nbsp;&nbsp; 2. 월세 매물 등록 시에 매물 가격을 단일 기본 가격으로 등록합니다. 
        단, 필요에 따라 기본 가격과 최소 보증금일 때에 적용될 최대 월세 가격과 최대 보증금일 때에 적용될 최소 월세 가격을 등록할 수 있습니다.<br></br>
   
        ④매물 입주 가능 날짜 정보 입력
        <br></br>
        매물 등록 시 회원이 입주를 희망할 경우 입주가 가능한 제일 빠른 날짜를 입력해야 하며 해당 날짜 이후부터는 아무때나 입주가 가능해야 합니다.<br></br>
        */}  
        ③기본 정보 입력
        <br></br>
        &nbsp;&nbsp; 1. 부동산의 유형, 건물의 층수 등의 정보를 정확하게 입력해야 합니다.<br></br>
        &nbsp;&nbsp; 2. 건축물의 정보를 정확하게 입력해야 합니다.<br></br>
        &nbsp;&nbsp; 3. 해당 부동산의 면적을 정확하게 기재해야 합니다.<br></br>
        &nbsp;&nbsp; 4. 해당 부동산의 거실 또는 안방 등의 주실의 방향을 기준으로 방향 정보를 입력해야 합니다.<br></br>
        ④상세 설명 입력
        <br></br>
        &nbsp;&nbsp; 1. 주변 환경, 편의시설 등 부동산의 상세한 정보를 제목 또는 내용에 입력해야 합니다.<br></br>
        ⑤사진 등록
        <br></br>
        &nbsp;&nbsp; 1. 부동산의 사진은 최소로 메인사진 1장을 필수로 등록해야 합니다.<br></br>
        &nbsp;&nbsp; 2. 상호명 또는 연락처 등이 기재된 사진은 사용할 수 없습니다.<br></br>
        &nbsp;&nbsp; 3. 특정 로고 또는 워터마크가 들어간 사진은 사용할 수 없습니다.<br></br>
        ⑥등록 금지사항
        <br></br>
        &nbsp;&nbsp; 1. 회사에서 정한 부동산 외의 다른 광고는 등록이 불가합니다.<br></br>
        &nbsp;&nbsp; 2. 광고의 목적이 등록한 주소에 해당하는 부동산의 매매인 경우에는 등록이 불가합니다.<br></br>
        &nbsp;&nbsp; 3. 부동산 정보에 업체명, 이름 또는 연락처 등을 입력하는 것은 불가합니다.<br></br>
        &nbsp;&nbsp; 4. 저작권이 회원 본인이 아닌 제3자에게 있는 사진 또는 정보를 사용할 수 없으며 회사는 이에 대하여 보증하지 않으며 책임지지 않습니다.
        <br></br>
        <br></br>
        </div>

        <div style={titleStyle}>
        제 4조 (규정의 시행)
        </div>
        <div style={titleContextStyle}>
        ①회사는 등록되는 부동산에 관한 신고 접수 건에 대하여 검수하며 제5조 허위부동산 기준에 근거하여 조치를 할 수 있습니다.
        <br></br>
        ②한 계정에 허위부동산을 게재한 사실이 반복되어 적발된 경우 제6조 경고와 제재에 근거하여 조치를 할 수 있습니다.
        <br></br>
        ③규정에 어긋난 부동산을 발견하면 다른 회원들의 피해를 막기 위해 사전고지하지 않고 게시가 내려갈 수 있습니다.
        <br></br>
        <br></br>
        </div>

        <div style={titleStyle}>
        제 5조 (허위부동산 기준)
        </div>
        <div style={titleContextStyle}>
        ①허위부동산 기준
        <br></br>
        &nbsp;&nbsp; 1. 악의적으로 해당 부동산을 보여줄 수 없는 상항에서 해당 부동산을 보러 오게 해놓거나 예약을 취소하지 않은 경우.<br></br>
        &nbsp;&nbsp; 2. 해당 부동산의 사진이 관련 사진이 아니거나 실제와 다른 경우<br></br>
        &nbsp;&nbsp; 3. 해당 부동산의 사진 또는 항목이 부동산 정보 작성 규정에 어긋난 경우<br></br>
        &nbsp;&nbsp; 4. 해당 부동산의 위치가 실제와 다른 경우<br></br>
        {/* &nbsp;&nbsp; 5. 해당 부동산의 가격이 실제와 다른 경우<br></br> */}
        &nbsp;&nbsp; 5. 해당 부동산의 면적이 실제와 다른 경우<br></br>
        &nbsp;&nbsp; 6. 설명이 실제와 다른 경우<br></br> 
        &nbsp;&nbsp; 7. 도박, 광고, 비속어, 음란물, 회사 또는 제3자의 개인정보 권리 침해와 관련되 정보가 포함되어 있는 경우<br></br> 
        ②신고 방법 및 조치
        <br></br>
        &nbsp;&nbsp; 1. 신고는 해당 부동산 정보 페이지 내의 "신고하기" 버튼 클릭을 통해 신고<br></br>
        &nbsp;&nbsp; 2. 회사는 신고 내용 확인 후에 조치를 취합니다.<br></br>
        <br></br>
        </div>

        <div style={titleStyle}>
        제 6조 (경고와 제재)
        </div>
        <div style={titleContextStyle}>
        ①최근 1년 사이에 부동산 글 또는 부동산 추가 정보 글 중에 제재를 5회 이상 받은 부분이 있을 경우, 최근 1년 사이 해당 부분 제재가 5회 미만이 될 때까지 접근 제한.
        <br></br>
        <br></br>
        </div>

        <div style={titleStyle}>
        부칙
        </div>
        <div style={titleContextStyle}>
        이 약관은 2022년 1월 26일부터 시행한다.
        <br></br>
        <br></br>
        </div>
        </div>
      </React.Fragment>
    )
  }
}

export default ItemEstate
