import React from 'react'

const Navigation = props => {
  return(
    <React.Fragment>
      <div className="main-navigation">
        <nav>
          <div className="default">
            {
              window.innerWidth > 768 ?
                <ul>
                  <li>
                    <span className="icon-notice"></span>
                    <span>각종 주거 게시판</span>
                  </li>
                  <li>
                    <span className="icon-sale"></span>
                    <span>부동산 정보</span>
                  </li>
                  <li>
                    <span className="icon-move"></span>
                    <span>이사</span>
                  </li>
                  <li>
                    <span className="icon-interior"></span>
                    <span>인테리어</span>
                  </li>
                  <li>
                    <span className="icon-shopping"></span>
                    <span>가구 쇼핑</span>
                  </li>
                  <li>
                    <span className="icon-design"></span>
                    <span>건축물 설계</span>
                  </li>
                </ul>
                :
                <React.Fragment>
                  <ul>
                    <li>
                      <span className="icon-notice"></span>
                      <span>각종 주거 게시판</span>
                    </li>
                    <li>
                      <span className="icon-sale"></span>
                      <span>부동산 정보</span>
                    </li>
                    <li>
                      <span className="icon-move"></span>
                      <span>이사</span>
                    </li>
                  </ul>
                  <ul>
                    <li>
                      <span className="icon-interior"></span>
                      <span>인테리어</span>
                    </li>
                    <li>
                      <span className="icon-shopping"></span>
                      <span>가구 쇼핑</span>
                    </li>
                    <li>
                      <span className="icon-design"></span>
                      <span>건축물 설계</span>
                    </li>
                  </ul>
                </React.Fragment>
            }

          </div>
        </nav>
      </div>
    </React.Fragment>
  )
}

export default Navigation
