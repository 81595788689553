import React, { Component } from 'react'

class Terms extends Component {

  constructor(props) {
    super(props)

    this.state = {
      //
    }
  }

  render() {

    const mainTitleStyle = {
      fontSize: '32px',
      fontWeight: '700'
    }
    const mainSubTitleStyle = {
      fontSize: '24px',
      fontWeight: '600'
    }
    const titleStyle = {
      fontSize: '18px',
      fontWeight: '600'
    }
    const titleContextStyle = {
      fontSize: '14px'
    }

    return (
      <React.Fragment>
        <div className="policy-wrap">
          <div style={mainTitleStyle}>
            이용약관
          </div>
          <br></br>
          <div style={mainSubTitleStyle}>
          제1장 총칙
          </div>
          <div style={titleStyle}>
            제 1조 (목적)
          </div>
          <div style={titleContextStyle}>
            이 약관은 직관적합리주의 주식회사(이하 "회사")가 운영하는 너나방의 홈페이지와 모바일 어플리케이션에서 제공하는
            서비스들을 이용함에 있어서 이용자의 권리, 의무 등 기타 필요사항에 관한 필요 사항을 규정함을 목적으로 합니다.
            <br></br>
            <br></br>
          </div>

          <div style={titleStyle}>
            제 2조 (용어의 정의)
          </div>
          <div style={titleContextStyle}>
            ①이 약관에서 사용하는 용어의 정의는 다음과 같습니다.
            <br></br>
            &nbsp;&nbsp; 1. "교류 서비스" : 이용자 간에 컴퓨터 등의 정보 통신 설비를 통하여 게시판 또는 채팅 대화방에서 정보를 제공하고 제공받는 것을 말합니다.<br></br>
            &nbsp;&nbsp; 2. "부동산 정보 교환 서비스" : 이용자가 등록한 부동산의 정보를 컴퓨터 등의 정보 통신 설비를 통하여 서로간에 제공하고 제공받는 서비스를 말합니다.<br></br>
            &nbsp;&nbsp; 3. "이사 인력·차량 중개 서비스" : 이용자가 등록한 이사 공고 정보를 통하여 다른 이용자와 컴퓨터 등의 정보 통신 설비를 통하여 서로간에 구인하고 구직하는 서비스를 말합니다.<br></br>
            &nbsp;&nbsp; 4. "이용자" : 교류 서비스와 부동산 정보 교환 서비스와 이사 인력·차량 중개 서비스를 이용하는 일반 사용자 회원를 의미합니다.<br></br>
            &nbsp;&nbsp; 5. "일반 사용자 회원" : 너나방 서비스에 회원가입 절차를 완료한 개인을 의미합니다.<br></br>
            {/* &nbsp;&nbsp;5. "공인중개사 회원" : 일반 사용자 회원 중에서 회사가 정한 소정의 절차를 거쳐서 가입된 회원을 의미합니다.<br></br> */}
            {/* &nbsp;&nbsp;6. "교류 정보" : 이용자가 게시판에 제공한 정보나 특정 이용자에게 제공한 정보를 의미합니다.<br></br> */}
            {/* &nbsp;&nbsp;7. "부동산 정보" : 이용자가 부동산의 정보를 '부동산관리규정'에 맞게 작성한 너나방에 제공한 부동산의 정보와 다른 이용자들이 추가로 작성하여 제공한 정보 모두를 의미합니다.<br></br> */}
            &nbsp;&nbsp; 6. "부동산관리규정" : 회사가 이용자에게 보다 나은 '부동산 정보 교환 서비스'를 제공하기 위하여 부동산에 관한 규정을 정한 것을 의미합니다.<br></br>
            &nbsp;&nbsp; 7. "이사 구인구직 규정" : 회사가 이용자에게 보다 나은 '이사 인력·차량 중개 서비스'를 제공하기 위하여 서비스를 이용하기 위한 규정을 정한 것을 의미합니다.<br></br>
            &nbsp;&nbsp; 8. "게시물" : 이용자가 서비스 내에 제공한 정보 모두를 의미합니다.<br></br>
            {/* &nbsp;&nbsp;9. "포인트" : 이용자가 중개 서비스 활동의 참여를 통해 얻게되는 포인트를 의미합니다.<br></br> */}
            ②제1항에서 정의되지 않은 용어의 의미는 관련 법령 및 일반적인 관행을 따릅니다.
            <br></br>
            <br></br>
          </div>

          <div style={titleStyle}>
            제 3조 (약관의 명시, 효력 및 변경)
          </div>
          <div style={titleContextStyle}>
            ①회사는 정의된 약관과 상호 및 대표자 성명, 사업자 등록번호 등을 이용자가 알 수 있도록 회사의 서비스가 제공되는 인터넷 사이트 (http://www.neonabang.com)의 첫 화면에 게시합니다.
            <br></br>
            ②회사는 합리적인 사유가 발생하면 약관의 규제에 관한 법률, 전자거래기본법, 전자서명법, 정보통신망 이용촉진 및 정보보호 등에 관한 법률,
            등에서의 소비자보호에 관한 법률 등 관련 법령에 위배되지 않는 범위 안에서 이 약관을 변경 할 수 있으며, 회사가 약관을 변경할 때에는
            적용일자와 변경사유를 밝혀 그 적용일자 15일 전부터 서비스 사이트에 공지합니다. 다만, 이용자에게 불리한 약관을 변경할 때에는
            해당 내용을 그 적용일자 30일 전부터 공지하며,
            이메일, 문자메시지 등으로 이용자에게 개별 통지합니다(이용자의 연락처 미기재, 변경 등으로 개별 통지가 어려울 때에는 본 약관에 따라 공지를 하는 것을 개별 통지한 것으로 간주합니다).
            <br></br>
            ③이 약관에 동의하는 것은 정기적으로 회사가 운영하는 인터넷 사이트를 방문하여 약관의 변경사항을 확인하는 것에 동의함을 의미합니다.
            변경된 약관에 대한 정보를 알지 못하여 발생하는 회원 또는 이용자의 피해에 대해 회사는 책임을 지지 않습니다.
            <br></br>
            ④변경된 약관에 동의하지 않는 회원은 회원 탈퇴(이용계약의 해지)를 요청할 수 있으며, 회사가 제2항에 따라 이용자에게 약관 변경 적용일까지 거부의사를 표시하지 않으면,
            약관의 변경에 동의한 것으로 간주한다는 내용을 공지 또는 통지하였음에도 이용자가 명시적으로 약관 변경에 대한 거부의사를 표시하지 않으면, 회사는 이용자가 변경된 약관에 동의한 것으로 간주합니다.
            <br></br>
            ⑤본 약관에서 정하지 아니한 사항과 해석에 관해서는 관련 법령 또는 상관례에 따릅니다.
            <br></br>
            <br></br>
          </div>

          <div style={titleStyle}>
            제 4조 (서비스의 제공 및 변경)
          </div>
          <div style={titleContextStyle}>
            ①회사는 다음과 같은 서비스를 제공합니다.
            <br></br>
            &nbsp;&nbsp;1. 이용자들 간의 커뮤니티 및 대화 서비스<br></br>
            &nbsp;&nbsp;2. 부동산 전반에 관한 정보 제공 서비스<br></br>
            &nbsp;&nbsp;3. 이사를 하기 위해 필요한 차량 또는 인력의 구인구직 서비스<br></br>
            ②회사는 제공하고 있는 서비스의 내용을 운영상, 기술적 사양 등의 사유로 일부 또는 전부 변경할 수 있습니다.
            <br></br>
            ③서비스의 변경이 실질적으로 이용자에게 불리한 것이 아닌 경우 그 사유를 이용자에게 통지하거나, 이용자가 알아볼 수 있도록 공지사항으로 게시합니다.
            <br></br>
            ④회사는 부동산의 정보 제공와 교류 서비스를 목적으로 이용자에게 이메일, 문자 메시지, 푸시 알림, 알림톡을 발송 할 수 있습니다.
            <br></br>
            ⑤회사는 정책에 따라 회원 간의 차별화된 유료 서비스를 제공할 수 있습니다.
            <br></br>
            <br></br>
          </div>

          <div style={titleStyle}>
            제 5조 (서비스의 중단)
          </div>
          <div style={titleContextStyle}>
            ①회원의 서비스 기간은 회사에 서비스를 신청하여 이용 승낙이 있은 날로부터 이용 계약 해지 시까지입니다.
            <br></br>
            ②회사는 컴퓨터 등 정보통신설비의 보수, 점검, 교체 및 고장, 통신의 두절 등의 사유가 발생하면 서비스를 일시적으로 중단할 수 있으며
            그럴 경우 서비스 일시 중단 사실과 그 사유를 사이트 초기 화면에 통지합니다.
            <br></br>
            ③회사는 천재지변 또는 이와 같은 불가항력으로 서비스를 제공할 수 없으면 서비스의 제공을 제한하거나 일시 중단할 수 있습니다.
            <br></br>
            <br></br>
          </div>

          <div style={titleStyle}>
            제 6조 (대리 및 보증 행위의 부인)
          </div>
          <div style={titleContextStyle}>
            ①회사는 이용자에게 서비스를 제공하기 위한 시스템을 운영 및 관리 책임만을 부담하며,
            회원이 서비스를 통해 제공한 정보를 이용하는 중에 발생한 사고 당사자 중 어느 일방을 대리하지 않습니다.
            <br></br>
            ②회사는 관련 법령 또는 회사의 정책에 따라 서비스 관리자로서의 의무를 이행할 뿐, 이용자가 등록한 정보에 대한 정확성, 합법성, 무결성 등을 보증하지 않습니다.
            <br></br>
            <br></br>
          </div>

          <div style={titleStyle}>
            제 7조 (이용자에 대한 통지 및 공지)
          </div>
          <div style={titleContextStyle}>
            ①회사는 이메일, 메시지 또는 푸시 알림 등으로 이용자에게 통지 또는 공지할 수 있습니다.
            <br></br>
            <br></br>
          </div>

          <div style={titleStyle}>
            제 8조 (회원가입)
          </div>
          <div style={titleContextStyle}>
            ①개인이 회사가 정한 가입 방식을 따른 후에 약관에 동의한다는 의사표시를 함으로써 회원 가입을 신청합니다.
            <br></br>
            ②회사는 제1항과 같이 회원으로 가입할 것을 신청한 이용자가 다음 각 호에 해당하지 않는 한 회원신청을 승낙합니다.
            <br></br>
            &nbsp;&nbsp;1. 가입 신청자가 이전에 약관 및 회사운영정책 위반 등으로 회원 자격을 상실한 적이 있는 경우(다만, 회원 자격 상실 후 회원재가입에 대한 회사의 승낙을 얻은 경우에는 예외로 합니다.)<br></br>
            &nbsp;&nbsp;2. 회사로부터 회원 자격 정지 조치 등을 받은 회원이 그 조치 기간 중에 이용 계약을 임의 해지하고 가입 신청을 하는 경우<br></br>
            &nbsp;&nbsp;3. 회원가입 신청자가 만 14세 미만인 경우<br></br>
            &nbsp;&nbsp;4. 기타 본 약관에 위배되거나 위법 또는 부당한 이용신청임이 확인된 경우
            <br></br>
            <br></br>
          </div>

          <div style={titleStyle}>
            제 9조 (이용계약의 해지 및 회원 탈퇴)
          </div>
          <div style={titleContextStyle}>
            ①회원은 회사에 아무때에나 회원 탈퇴를 요청하여 이용 계약을 해지할 수 있습니다.
            <br></br>
            ②제1항에 따라 해지를 한 회원은 동일한 아이디로 재가입을 할 수 없고 다른 아이디로 재가입을 할 수 있습니다.
            다만, 회원의 중복 참여가 제한된 할인쿠폰, 이벤트 등을 통하여 경제적 이익을 편법으로 수취하고자 회원 탈퇴 후 다시 가입하거나 타인의 명의를
            무단으로 사용하는 등의 경우에는 회사가 이를 확인하여 회원의 이용 계약을 해지할 수 있습니다.
            <br></br>
            <br></br>
          </div>

          <div style={titleStyle}>
            제 10조 (개인정보의 변경 및 보호)
          </div>
          <div style={titleContextStyle}>
            ①회원은 회원 가입 시 기재한 사항이 변경되었을 경우에는 상당한 기간 이내에
            회원정보 수정 등의 방법으로 그 변경사항을 수정하여야 합니다. 단, 아이디 등 변경할 수 없는 사항은 수정할 수 없습니다.
            <br></br>
            ②수정하지 않은 정보로 말미암아 발생하는 손해는 해당 회원이 부담하며, 회사는 이에 대해 아무런 책임을 지지 않습니다.
            <br></br>
            ③회사는 이용자의 정보를 서비스를 제공하기 위한 목적 이외의 용도로 사용하거나 이용자의 동의 없이 제3자에게 제공하지 않습니다. 다만 관련 법령에 달리 정함이 있는 경우에는 예외로 합니다.
            <br></br>
            ④회사는 관련법령이 정하는 바에 따라서 이용자의 개인정보를 보호하기 위하여 노력하며, 이용자의 개인정보를 보호하기 위한 정책을 수립하고 개인정보보호 책임자를 지정하여 이를 게시합니다.
            <br></br>
            ⑤이용자의 개인정보보호는 관련 법령 및 회사가 정하는 개인정보처리방침에서 정한 바를 따릅니다.
            <br></br>
            <br></br>
          </div>

          <div style={titleStyle}>
            제 11조 (교류 서비스의 이용)
          </div>
          <div style={titleContextStyle}>
            ①이용자는 교류 서비스에서 이용자에게 글, 그림 등의 다양한 형태의 정보를 제공하는 경우, 반드시 이용자의 의무에 맞추어 제공해야 합니다.
            <br></br>
            ②회사는 해당 서비스 이용 중 발생한 일에 대하여 일절 책임지지 않습니다.
            <br></br>
            ③이용자는 자신이 제공한 정보에 대해서 법적인 책임을 집니다.
            <br></br>
            <br></br>
          </div>

          <div style={titleStyle}>
            제 12조 (부동산 정보 교환 서비스의 이용)
          </div>
          <div style={titleContextStyle}>
            ①이용자는 부동산 정보 교환 서비스에서 정보를 제공할 경우, 반드시 기본적으로 제공할 것으로 지정되어 있는 항목들을 부동산관리규정에 맞게 기재하여야 합니다.
            <br></br>
            ②이용자는 부동산관리규정을 준수하여야 합니다.
            <br></br>
            ③회사는 해당 서비스 이용 중 발생한 일에 대하여 일절 책임지지 않습니다.
            <br></br>
            ④이용자는 자신이 제공한 정보에 대해서 법적인 책임을 집니다.
            <br></br>
            <br></br>
          </div>

          <div style={titleStyle}>
            제 13조 (이사 인력·차량 중개 서비스)
          </div>
          <div style={titleContextStyle}>
            ①이용자는 이사 인력·차량 구인 정보를 제공할 경우, 반드시 기본적으로 제공할 것으로 지정되어 있는 항목들을 이사 구인구직 규정에 맞게 기재하여야 합니다.
            <br></br>
            ②이용자는 이사 구인구직 규정을 준수하여야 합니다.
            <br></br>
            ③회사는 해당 서비스 이용 중 발생한 일에 대하여 일절 책임지지 않습니다.
            <br></br>
            ④이용자는 자신이 제공한 정보에 대해서 법적인 책임을 집니다.
            <br></br>
            <br></br>
          </div>

          <div style={titleStyle}>
            제 14조 (콘텐츠에 대한 권리 및 책임)
          </div>
          <div style={titleContextStyle}>
            ①이용자는 직접 작성한 콘텐츠에 대해 저작권을 가집니다.
            <br></br>
            ②이용자가 작성한 콘텐츠가 관련 법령을 위반하거나 제3자의 권리를 침해하는 경우에는 이용자가 그로 인한 모든 책임을 부담하여야 하고,
            회사는 콘텐츠에 대한 검수 의무를 지지 않으며, 이에 대한 어떠한 민/형사상의 책임도 부담하지 않습니다.
            <br></br>
            ③이용자가 직접 작성한 콘텐츠를 등록할 경우, 별도의 동의나 서면합의 없이도 회사에 다음과 같은 해당 콘텐츠 이용 권한을 부여합니다.
            <br></br>
            &nbsp;&nbsp; 1. 콘텐츠 이용 목적 : 회사의 부동산 거래 정보 제공 서비스 운영, 회사 서비스 기획 운영, 시장 조사를 위한 빅데이터 분석<br></br>
            &nbsp;&nbsp; 2. 콘텐츠 이용 권한 : 복제, 배포, 전시, 수정, 파생저작물 제작이 가능한 로열티 없는 전 세계적인 라이센스 권한.
            <br></br>
            <br></br>
          </div>

          <div style={titleStyle}>
            제 15조 (회사의 의무)
          </div>
          <div style={titleContextStyle}>
            ①회사는 법령과 약관이 금지하거나 공서양속에 위배되는 행위를 하지 않습니다.
            <br></br>
            ②회사는 정한 약관에 따라 이용자에게 안정적인 서비스를 제공하기 위하여 노력할 의무가 있습니다.
            <br></br>
            ③회사는 이용자 상호 간의 발생하는 일에 대해서 보증을 제공하지 않고 있으며, 일이 발생하는 경우에 회사는 일절 책임을 부담하지 않습니다.
            <br></br>
            <br></br>
          </div>

          <div style={titleStyle}>
            제 16조 (이용자의 의무)
          </div>
          <div style={titleContextStyle}>
            ①이용자는 다음 각 호의 행위들을 해서는 안 됩니다.
            <br></br>
            &nbsp;&nbsp; 1. 타인의 정보를 도용하는 행위<br></br>
            &nbsp;&nbsp; 2. 회사 또는 서비스 운영 및 이용을 방해하는 행위<br></br>
            &nbsp;&nbsp; 3. 회원 정보에 거짓으로 정보를 입력하는 행위<br></br>
            &nbsp;&nbsp; 4. 게시물을 허위로 입력하는 행위<br></br>
            &nbsp;&nbsp; 5. 부동산 매매에 관한 정보를 등록하는 행위<br></br>
            &nbsp;&nbsp; 6. 회사 또는 기타 제3자의 저작권 등을 침해하는 행위<br></br>
            &nbsp;&nbsp; 7. 회사 또는 기타 제3자의 개인정보, 권리 또는 명예를 훼손 및 유포 행위<br></br>
            &nbsp;&nbsp; 8. 비속어 또는 음란물을 입력 및 유포하는 행위<br></br>
            &nbsp;&nbsp; 9. 도박, 광고 등의 정보를 게시하는 행위<br></br>
            &nbsp;&nbsp; 10. 이용약관 또는 회사운영정책을 위반하는 행위<br></br>
            ②이용자가 밑의 부분 중에서 제1항과 같은 행위를 하여 제재를 당한 횟수가 최근 1년 사이에 5회 이상인 것으로 확인되는 부분이 있는 경우, 
            해당 부분의 최근 1년 사이 제재 내역이 5회 미만이 될 때까지 접근이 제한됩니다. <br></br>
            &nbsp;&nbsp; *부분은 '교류 서비스', '부동산 정보 교환 서비스'의 부동산 글, '부동산 정보 교환 서비스'의 부동산 추가 정보글과 '이사 인력·차량 중개 서비스'를 의미
            <br></br>
            ③이용자의 행위로 인하여 회사 또는 제3자에게 손해가 발생하는 경우, 해당 이용자는 발생하게 되는 모든 손해를 배상하여야 하며 회사는 그에 대한 민·형사상의 조치를 취할 수 있습니다.
            <br></br>
            <br></br>
          </div>

          <div style={titleStyle}>
            제 17조 (저작권의 귀속 및 이용제한)
          </div>
          <div style={titleContextStyle}>
            ①이용자는 서비스를 이용함으로써 얻은 정보·콘텐츠 중 회사 또는 제3자가 지적재산권을 보유하고 있는 정보·콘텐츠를 회사 또는 제3자의 사전 승낙 없이
            수정, 복제, 송신, 출판, 배포, 방송 등 기타 방법에 의하여 이용하거나 제3자에게 이용하게 하여서는 안 됩니다.
            <br></br>
            ②회사는 이용자가 게시한 정보를 다음 목적을 위해 무상으로 이용할 수 있습니다.
            <br></br>
            &nbsp;&nbsp; 1. 서비스 운영 및 홍보<br></br>
            &nbsp;&nbsp; 2. 부동산과 관련한 자료 작성<br></br>
            &nbsp;&nbsp; 3. 이사에 관련한 자료 작성
            <br></br>
            <br></br>
          </div>

          <div style={titleStyle}>
            제 18조 (회사의 책임 및 면책사항)
          </div>
          <div style={titleContextStyle}>
            ①회사의 고의 또는 중대한 과실로 인하여 이용자에게 손해가 발생한 경우, 회사는 관련 법령에서 정하는 범위 내에서 그 손해를 배상합니다.
            다만, 아래의 사항에 대해서는 회사가 책임을 부담하지 않습니다.
            <br></br>
            &nbsp;&nbsp; 1. 천재지변, 전쟁 및 기타 이에 준하는 불가항력으로 인하여 서비스를 제공할 수 없는 경우<br></br>
            &nbsp;&nbsp; 2. 서비스용 설비의 보수, 교체, 점검, 공사 등 서비스 운영에 문제가 발생하였으나 회사에 고의 또는 과실이 없는 경우<br></br>
            &nbsp;&nbsp; 3. 기간통신 사업자의 전기통신 서비스가 정상적으로 제공하지 않아 서비스가 중단된 경우<br></br>
            &nbsp;&nbsp; 4. 제3자가 악성프로그램을 전송, 유포하거나 회사 서버에 대한 공격, 방해, 불법 접속 및 이용 등으로 인하여 장애 또는 손해가 발생한 경우<br></br>
            ②이용자의 귀책 사유로 인한 서비스 이용의 장애 또는 손해가 발생한 경우
            <br></br>
            ③이용자가 정보를 잘못 작성하여 발생한 경우
            <br></br>
            ④이용자가 서비스를 이용하여 기대하는 수익을 얻지 못하거나 상실한 것에 대한 경우
            <br></br>
            ⑤회사는 이용자 또는 제3자 사이에 발생한 분쟁에 대해 개입할 의무가 없으며 이로 인한 손해를 책임지지 않으며, 어떠한 보증도 제공하지 않습니다.
             따라서 분쟁은 분쟁당사자들이 직접 해결하여야 합니다.
            <br></br>
            <br></br>
          </div>

          <div style={titleStyle}>
        부칙
        </div>
        <div style={titleContextStyle}>
        이 약관은 2022년 1월 26일부터 시행한다.
        <br></br>
        <br></br>
        </div>
        </div>
      </React.Fragment>
    )
  }
}

export default Terms
