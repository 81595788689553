import React, { Component } from 'react'

class ItemEstate extends Component {

  constructor(props) {
    super(props)

    this.state = {
      //
    }
  }

  render() {

    const mainTitleStyle = {
      fontSize: '32px',
      fontWeight: '700'
    }
    const mainSubTitleStyle = {
      fontSize: '24px',
      fontWeight: '600'
    }
    const titleStyle = {
      fontSize: '18px',
      fontWeight: '600'
    }
    const titleContextStyle = {
      fontSize: '14px'
    }

    return (
      <React.Fragment>
        <div className="policy-wrap">
        <div style={mainTitleStyle}>
        이사 구인구직 규정
        </div>

        <div style={titleStyle}>
        제 1조 (규정의 목적)
        </div>
        <div style={titleContextStyle}>
        이 정책은 회원들 간의 신뢰를 높이고 직관적합리주의 주식회사(이하 "회사")가 운영하는 너나방의 인터넷 사이트 또는 모바일 어플리케이션 서비스(이하 "서비스")의 질을 높임으로써
        이사 인력·차량 중개 서비스 활성화를 목적으로 한다.
        <br></br>
        <br></br>
        </div>

        <div style={titleStyle}>
        제 2조 (정책 적용 대상)
        </div>
        <div style={titleContextStyle}>
        이 정책은 회사가 제공하는 서비스의 전 회원에 적용됩니다.
        <br></br>
        <br></br>
        </div>

        <div style={titleStyle}>
        제 3조 (이사 차량·인력 구인 공고 작성 규정)
        </div>
        <div style={titleContextStyle}>
        ①출발 정보 입력
        <br></br>
        &nbsp;&nbsp; 1. 이사의 출발 주소를 정확하게 입력해야 합니다.<br></br>
        &nbsp;&nbsp; 2. 이사 출발 주소지의 타입, 층수, 엘리베이터 유무 등을 정확하게 입력해야 합니다.<br></br>

        ②도착 정보 입력
        <br></br>
        &nbsp;&nbsp; 1. 이사의 도착 주소를 정확하게 입력해야 합니다.<br></br>
        &nbsp;&nbsp; 2. 이사 도착 주소지의 타입, 층수, 엘리베이터 유무 등을 정확하게 입력해야 합니다.<br></br>

        ③이사 날짜 입력
        <br></br>
        &nbsp;&nbsp; 1. 이사가 진행될 날짜를 입력해야 합니다.<br></br>
        &nbsp;&nbsp; 2. 이사가 시작될 시간과 이사가 끝나게 될 시간을 예상 시간을 입력해야 합니다.<br></br>

    ④필요 차량 정보 입력
        <br></br>
        &nbsp;&nbsp; 1. 인력만 필요한지 차량도 필요한지 입력해야 합니다.<br></br>
        &nbsp;&nbsp; 2. 차량도 필요한 경우에는 필요 차량 대수, 최소 필요 차랑 크기, 차량과 인력을 동시에 지원하는 이용자의 일당을 추가로 입력해야 합니다.<br></br>

    ④필요 인력 정보 입력
        <br></br>
        &nbsp;&nbsp; 1. 차량 지원 인력을 제외하고 몇 명의 인력이 필요한지 입력해야 합니다.<br></br>
        &nbsp;&nbsp; 2. 인력만을 지원하는 이용자의 일당을 입력해야 합니다.<br></br>

    ④상세 설명 입력
        <br></br>
        &nbsp;&nbsp; 1. 구인하는 이용자들이 업무를 파악할 수 있도록 상세한 업무 내용 등을 제목 또는 내용에 입력해야 합니다.<br></br>
      
        ⑤사진 등록
        <br></br>
        &nbsp;&nbsp; 1. 최소로 메인사진 1장을 필수로 등록해야 합니다.<br></br>
        &nbsp;&nbsp; 2. 상호명 또는 연락처 등이 기재된 사진은 사용할 수 없습니다.<br></br>
        &nbsp;&nbsp; 3. 특정 로고 또는 워터마크가 들어간 사진은 사용할 수 없습니다.<br></br>

        ⑥등록 금지사항
        <br></br>
        &nbsp;&nbsp; 1. 정보에 업체명, 이름 또는 연락처 등을 입력하는 것은 불가합니다.<br></br>
        &nbsp;&nbsp; 2. 저작권이 회원 본인이 아닌 제3자에게 있는 사진 또는 정보를 사용할 수 없으며 회사는 이에 대하여 보증하지 않으며 책임지지 않습니다.
        <br></br>
        <br></br>
        </div>

        <div style={titleStyle}>
        제 4조 (규정의 시행)
        </div>
        <div style={titleContextStyle}>
        ①회사는 등록되는 이사 차량·인력 구인 공고에 관한 신고 접수 건에 대하여 검수하며 제5조 허위 이사 공고 기준에 근거하여 조치를 할 수 있습니다.
        <br></br>
        ②한 계정에 허위 이사 공고를 게재한 사실이 반복되어 적발된 경우 제6조 경고와 제재에 근거하여 조치를 할 수 있습니다.
        <br></br>
        ③규정에 어긋난 부동산을 발견하면 다른 회원들의 피해를 막기 위해 사전고지하지 않고 게시가 내려갈 수 있습니다.
        <br></br>
        <br></br>
        </div>

        <div style={titleStyle}>
        제 5조 (허위 이사 공고 기준)
        </div>
        <div style={titleContextStyle}>
        ①허위 이사 공고 기준
        <br></br>
        &nbsp;&nbsp; 1. 해당 이사 공고의 사진이 관련 사진이 아니거나 실제와 다른 경우<br></br>
        &nbsp;&nbsp; 2. 해당 이사 공고의 출발 또는 도착의 주소지가 실제 이사의 출발 또는 도착 주소지와 직선거리가 100m 이상 떨어져 있는 경우<br></br>
        &nbsp;&nbsp; 3. 설명이 실제와 다른 경우<br></br>
        &nbsp;&nbsp; 5. 도박, 광고, 비속어, 음란물, 회사 또는 제3자의 개인정보 권리 침해와 관련된 정보가 포함되어 있는 경우<br></br> 
        ②신고 방법 및 조치
        <br></br>
        &nbsp;&nbsp; 1. 신고는 해당 부동산 정보 페이지 내의 "신고하기" 버튼 클릭을 통해 신고<br></br>
        &nbsp;&nbsp; 2. 회사는 신고 내용 확인 후에 조치를 취합니다.<br></br>
        <br></br>
        </div>

        <div style={titleStyle}>
        제 6조 (경고와 제재)
        </div>
        <div style={titleContextStyle}>
        ①최근 1년 사이에 이사 공고 관련하여 제재를 5회 이상 받은 경우, 최근 1년 사이 제재가 5회 미만이 될 때까지 접근 제한.
        <br></br>
        <br></br>
        </div>

        <div style={titleStyle}>
        부칙
        </div>
        <div style={titleContextStyle}>
        이 약관은 2022년 1월 26일부터 시행한다.
        <br></br>
        <br></br>
        </div>
        </div>
      </React.Fragment>
    )
  }
}

export default ItemEstate
