import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import Scroll from '../../hook/scroll'

const Header = props => {

  const [ state, setState ] = useState({
    isOpen: false
  })

  const {scroll, direction} = Scroll()

  const handleOpen = () => {
    setState({
      isOpen: !state.isOpen
    })
  }

  return(
    <React.Fragment>
      <header className=
        {`${direction && scroll !== 0 ? props.class + ' scrolled scrolled-down' : scroll ? props.class + ' scrolled scrolled-up' : props.class}`}
      >
        <div className={`${state.isOpen ? 'open' : ''}`}>
          <div className="header-logo">
            <Link to="/">
              <div>너나방</div>
            </Link>
          </div>

          <div className={window.innerWidth > 768 ? '' : 'mobile-nav'}>
            <div className="default">
              <ul>
                {/* <li>게시판 (준비중)</li> */}
                {/* <li>부동산 매물 찾기 (준비중)</li> */}
                <li>(준비중)</li>
              </ul>
            </div>
            {
              window.innerWidth < 769 ?
                <div className="mobile-header-btn-box">
                  <button className="btn btn-login">로그인</button>
                </div>
                : ''
            }
          </div>
          <div className="header-btn-box">
            <button className="btn btn-kakao flex-c-m"><span>카카오톡 문의하기</span><span className="icon-kakao"></span></button>

            {
              window.innerWidth > 768 ?
                <button className="btn btn-login">로그인</button>
                :
                <button className={`btn m-l-15 ${state.isOpen ? 'icon-close' : 'icon-mobile-menu'}`} onClick={() => handleOpen()}></button>
            }
          </div>
        </div>
      </header>
    </React.Fragment>
  )
}

export default Header
