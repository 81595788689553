import {modalConstants} from '../../constants'

const initialState = {
  isShow: false,
  type: ''
}

export default function modal(state = initialState, action) {
  switch (action.type) {

    case modalConstants.MODAL_REQUEST:
      return {
        ...state,
        type: action.params,
        isShow: !state.isShow
      }

    default:
      return state
  }
}
