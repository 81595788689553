import React, { Component } from 'react'
import Route from './route'

class APP extends Component {
  render() {
    return (
      <React.Fragment>
        <Route />
      </React.Fragment>
    )
  }
}

export default APP
