import React from 'react'
import { Router, Route, Switch } from 'react-router-dom'
import history from './history'
import ScrollToTop from './ScrollToTop'

/*************
    LayOut
*************/

import DefaultLayout from '../layout/DefaultLayout'
import SubLayout from '../layout/SubLayout'

/*************
     Home
*************/

import Home from '../component/home/Index'

import Introduce from '../component/home/Introduce'
import Service from '../component/home/terms/service/220126'
import Privacy from '../component/home/terms/privacy/220126'
import ItemEstate from '../component/home/terms/item_estate/220126'
import ItemMove from '../component/home/terms/item_move/220126'

/*************
     404
*************/

import NotFound from '../component/home/NotFound'

const Routes = ({ auth }) => {
  return (
    <Router
      history={history}
    >
      <ScrollToTop />
      <Switch>

        <Route exact path={[
          '/',
        ]}>

          <DefaultLayout>
            <Switch>
              <Route exact path='/' component={Home} />
            </Switch>
          </DefaultLayout>
        </Route>

        <Route exact path={[
          '/introduce',
          '/terms/service',
          '/terms/privacy',
          '/terms/item_estate',
          '/terms/item_move',
        ]}>

          <SubLayout>
            <Switch>
              <Route exact path='/introduce' component={Introduce} />
              <Route exact path='/terms/service' component={Service} />
              <Route exact path='/terms/privacy' component={Privacy} />
              <Route exact path='/terms/item_estate' component={ItemEstate} />
              <Route exact path='/terms/item_move' component={ItemMove} />
            </Switch>
          </SubLayout>
        </Route>

        {/***********
            404
        ***********/}

        <Route path='*' component={NotFound} />

      </Switch>

    </Router>

  )
}

export default Routes
