import React, { Component } from 'react'
import Navigation from '../../layout/default/Navigation'

import Section01Img from '../../assets/images/main-section-01.png'
import Section02Img from '../../assets/images/main-section-02.png'
import Section03Img from '../../assets/images/main-section-03.png'
import Section04Img from '../../assets/images/main-section-04.png'
import Section05Img from '../../assets/images/main-section-05.png'

class Index extends Component {

  constructor(props) {
    super(props)

    this.state = {
      //
    }
  }

  render() {

    const pointer = {
      cursor: "pointer"
    }

    const aosDownload = function(){
      // 1. aos os인지 확인.
      var os;
      var mobile = (/iphone|ipad|ipod|android/i.test(navigator.userAgent.toLowerCase()));
      if (mobile) {
        var userAgent = navigator.userAgent.toLowerCase();
        if (userAgent.search("android") > -1) {
          os = "android";
        }
        else if ((userAgent.search("iphone") > -1) || (userAgent.search("ipod") > -1) || (userAgent.search("ipad") > -1)) {
          os = "ios";
        }
        else {
          os = "other";
        }
      }
      else {
        os = "pc";
      }
      // 2. aos일 경우, 마켓 실행.
      // 3. aos 아닐 경우, WEB 이동.
      const aosURL = "market://details?id=" + "com.neonabang.neonabang"; //안드로이드 마켓 주소
      const aosAlterURL = "https://play.google.com/store/apps/details?id=" + "com.neonabang.neonabang"; //안드로이드 웹 주소

      if (os == 'android') {
        window.location.href = aosURL
      } else {
        window.location.href = aosAlterURL
      }
    }

    const iosDownload = function(){
      // ios는 설치 안되어 있을 경우, 자동으로 WEB으로 이동시켜준다.
      const iosURL = "https://itunes.apple.com/kr/app/" + "id1596933368"; //ios 마켓 주소
      window.location.href = iosURL
    }

    return (
      <React.Fragment>
        <main>
          <div className="content">
            <section className="section-main section-01">
              <div>
                <div className="section-main-text">
                  <div className="explain m-b-50">주거에 관한<br />모든 것을<br />모으다!</div>
                  <div className="download-btn-box flex-sa">

                    <div style={pointer} onClick={aosDownload}>
                      <span className="icon-google-download"></span>
                      <button className="btn btn-aos m-r-10">Google Play</button>
                    </div>

                    <div style={pointer} onClick={iosDownload}>
                      <span className="icon-ios-download"></span>
                      <button className="btn btn-ios">App Store</button>
                    </div>

                  </div>
                </div>
                <img src={Section01Img} alt="" />
              </div>
            </section>

            <Navigation />

            <section className="section-main section-02">
              <div>
                <div className="flex-c-m">
                  <img src={Section02Img} alt="" />
                </div>
                <div className="text-box">
                  <h4>
                    <span>부동산 정보를</span>
                    <br />
                    <span>편하게 찾아보다</span>
                  </h4>
                  <span className="line"></span>
                  <h5>주변의 부동산 정보들을<br />편하게 찾아보세요</h5>
                </div>
              </div>
            </section>

            <section className="section-main section-03">
              <div>
                <div className="text-box">
                  <h4>
                    <span>귀찮은 과정 없이 편하게</span>
                    <br />
                    <span>부동산을 보러 갈 시간을 정하다</span>
                  </h4>
                  <span className="line"></span>
                  <h5>부동산 보기위해 연락할 필요 없이,<br />버튼 클릭 한번으로 예약을 할 수 있어요</h5>
                </div>
                <img src={Section03Img} alt="" />
              </div>
            </section>

            <section className="section-main section-04">
              <div>
                <img src={Section04Img} alt="" />
                <div className="text-box">
                  <h4>
                    <span>정보를 다른 이용자들이 작성하여</span>
                    <br />
                    <span>더 자세한 부동산 정보를 제공하다</span>
                  </h4>
                  <span className="line"></span>
                  <h5>부동산을 보고 난 이용자들이 정보를 추가 작성하여, <br />사진으로 알 수 없는 것들과 단점들을 미리 파악할 수 있어요</h5>
                </div>
              </div>
            </section>

            <section className="section-main section-05">
              <div>
                <div className="text-box">
                  <h4>
                    <span>주거관련 게시판</span>을
                    <br />
                    <span>하나로 모으다</span>
                  </h4>
                  <span className="line"></span>
                  <h5>주거에 대한 각종 정보를 알아보기 위해서 주거 게시판이 아닌<br />여러 커뮤니티를 돌아다니실 필요가 없어요</h5>
                </div>
                <img src={Section05Img} alt="" />
              </div>
            </section>

            <section className="section-06">
              <div className="text-box">
                <div>
                  <h1>너나방</h1>
                  <h5>주거의 관한 모든것</h5>
                </div>
                <div className="download-btn-box">
                  <div className="flex-col-c m-r-10" >
                    <span className="btn-app-store" onClick={iosDownload}></span>
                  </div>
                  <div className="flex-col-c m-l-10" onClick={aosDownload}>
                    <span className="btn-google-play"></span>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </main>
      </React.Fragment>
    )
  }
}

export default Index
