import React, {useEffect} from 'react'

import Header from './default/Header'
import Footer from './default/Footer'

const SubLayout = ({children}) => {

  const setTitle = () => {
    document.title='너나방'
  }

  useEffect(() => {
    setTitle()
  }, [])

  return (
    <React.Fragment>
			<Header class={'sub'}/>
        {children}
      <Footer />
    </React.Fragment>
  )
}

export default SubLayout
