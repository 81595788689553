import React, {useEffect} from 'react'

import Header from './default/Header'
import Footer from './default/Footer'
import Navigation from "./default/Navigation";

const DefaultLayout = ({children}) => {

  const setTitle = () => {
    document.title='너와 나의 방, 너나방'
  }

  useEffect(() => {
    setTitle()
  }, [])

  return (
    <React.Fragment>
			<Header class={'main'}/>
        {children}
      <Footer />
    </React.Fragment>
  )
}

export default DefaultLayout
