import { saleConstants } from '../../constants'
import SaleAssignData from '../../../data/table.json'

const initialState = {
  params: '',
  data: []
}

export default function sale(state = initialState, action) {
  switch (action.type) {
    case saleConstants.SALE_ASSIGN_LIST_REQUEST:
      return {
        ...state,
        params: action.params,
        data: SaleAssignData.payload
      }

    default:
      return state
  }
}
