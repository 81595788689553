import React, { Component } from 'react'
import { Link } from 'react-router-dom'

class Footer extends Component {

  render() {

    return(
      <React.Fragment>
        <footer>
          <div>
            <div className="footer-top">
              <ul className="footer-link-box">
                <li><Link to="/introduce">회사소개</Link></li>
                <li>|</li>
                <li><Link to="/terms/service">이용약관</Link></li>
                <li>|</li>
                <li><Link to="/terms/privacy">개인정보처리방침</Link></li>
                <li>|</li>
                <li><Link to="/terms/item_estate">부동산관리규정</Link></li>
                <li>|</li>
                <li><Link to="/terms/item_move">이사 구인구직 규정</Link></li>
              </ul>
              <div className="flex-c-m">
                {/* <Link to="/admin"> <button className="btn">관리자 페이지</button> </Link> */}
              </div>
            </div>
            <span className="line"></span>
            {
              window.innerWidth > 768 ?
                <div className="footer-info-box">
                  <h5>직관적합리주의 주식회사(Jikgwanjeok Haprizuui Inc.)</h5>
                  <ul className="m-b-20">
                    <li>대표: 김일겸</li>
                    <li>|</li>
                    <li>서울시 서초구 강남대로61길10, 6층 607호(서초동, 센터프라자)</li>
                    <li>|</li>
                    <li>개인정보관리책임자: 김일겸</li>
                    <li>|</li>
                    <li>사업자 등록번호: 723-86-02314</li>
                    <li>|</li>
                    <li>neonabang@neonabang.com</li>
                  </ul>
                </div>
                :
                <div className="footer-bottom">
                  <div className="footer-info-box">
                    <h5>직관적합리주의 주식회사(Jikgwanjeok Haprizuui Inc.)</h5>
                    <ul className="m-b-20">
                      <li>대표: 김일겸</li>
                      <li>서울시 서초구 강남대로61길10, 6층 607호(서초동, 센터프라자)</li>
                      <li>개인정보관리책임자: 김일겸</li>
                      <li>사업자 등록번호: 723-86-02314</li>
                      <li>neonabang@gmail.com</li>
                    </ul>
                  </div>
                </div>
            }
          </div>
        </footer>
      </React.Fragment>
    )
  }
}

export default Footer
