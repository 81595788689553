export const authConstants = {
    LOGIN_REQUEST: 'USERS_LOGIN_REQUEST',
    LOGIN_SUCCESS: 'USERS_LOGIN_SUCCESS',
    LOGIN_FAILURE: 'USERS_LOGIN_FAILURE',

    LOGOUT: 'USERS_LOGOUT',

    USER_INFO_REQUEST: 'USERS_GET_USER_INFO_REQUEST',
    USER_INFO_SUCCESS: 'USERS_GET_USER_INFO_SUCCESS',
    USER_INFO_FAILURE: 'USERS_GET_USER_INFO_FAILURE',
}
