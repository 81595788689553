import { combineReducers } from 'redux'

import authReducer from './auth/auth.reducer'
import modalReducer from './modal/modal.reducer'
import saleReducer from './sale/sale.reducer'


const rootReducer = combineReducers({

  auth: authReducer,
  sale: saleReducer,

  modal: modalReducer,

})

export default rootReducer
