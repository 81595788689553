import React, { Component } from 'react'

class Terms extends Component {

  constructor(props) {
    super(props)

    this.state = {
      //
    }
  }

  render() {

    const mainTitleStyle = {
      fontSize: '32px',
      fontWeight: '700'
    }
    const mainSubTitleStyle = {
      fontSize: '24px',
      fontWeight: '600'
    }
    const titleStyle = {
      fontSize: '18px',
      fontWeight: '600'
    }
    const titleContextStyle = {
      fontSize: '14px'
    }

    return (
      <React.Fragment>
        <div className="policy-wrap">
          <div style={mainTitleStyle}>
            회사소개
          </div>
          <br></br>
          <div style={mainSubTitleStyle}>
            1. 법인명 소개
          </div>
          <div style={titleContextStyle}>
            저희는 이용자들에게 최적화된 다양한 서비스를 제공하고자 하며, 이를 위해서 저희는 끊임없이 많은 일들을 빠르고 훌륭하게 해나가야 합니다.
            <br></br>
            이를 위해, 저희는 수없이 마주치는 의사결정 순간순간마다 그간 쌓아온 경험과 지식 바탕으로 합리적인 방향을 직관적으로 빠르고 모색하여 앞의 산들을 빠르고 훌륭하게 헤쳐 나가겠다는 의미를 법인명에 담았습니다.
            <br></br>
            <br></br>
          </div>

          <div style={mainSubTitleStyle}>
            2. 너나방 소개
          </div>
          <div style={titleStyle}>
          ①주거 관련 게시판
          </div>
          <div style={titleContextStyle}>
            -주거 관련 게시판을 통하여 많은 사람들 간에 생활과 주거에 관련된 여러 정보들을 교류할 수 있게 하고자 하였습니다.
            <br></br>
            <br></br>
          </div>
          <div style={titleStyle}>
          ②주거용 부동산 정보 교환
          </div>
          <div style={titleContextStyle}>
          -서비스 내에 올라온 주거용 부동산 중에 보고 싶은 것이 있다면, 시간을 예약하고 해당 시간에 찾아가서 구경할 수 있습니다.<br></br>
            -너나방 서비스의 주거용 부동산을 찾아가서 봤다면, 해당 부동산에 대한 감상 후기를 작성하여 다른 사람들에게 알려줄 수 있습니다.
            <br></br>
            <br></br>
          </div>
          <div style={titleStyle}>
          ③개인간 이사 차량·인력 중개
          </div>
          <div style={titleContextStyle}>
          -최근에는 많은 가구들이 옵션화 되어있기 때문에 소형 가구가 이사할 때에 비싼 이사 전문 인력이 필요한 것이 아니라, 저렴하게 간단한 짐을 들고 실어줄 평범한 사람과 차량이 필요합니다.<br></br>
            -너나방 서비스는 이사를 하는 경우에, 이사 공고를 올려 이사를 도와줄 차량 또는 평범한 사람을 구할 수 있습니다.<br></br>
            -너나방 서비스는 용돈 벌이가 필요한 경우에, 서비스 내에 올라와 있는 이사 공고에 차량 또는 인력을 지원하여 용돈을 벌 수 있습니다.
            <br></br>
            <br></br>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

export default Terms
